import React from 'react';
import { PageProps } from './Page.types';
import { PageContainer, PageContainerFixedHeader } from './Page.styles';
import { doLogin, usePageView } from '../../hooks';
import { apiService } from '../../services';
import { InteractionEventType } from 'outbound-api-client/lib/models';
import { matchRoutes, RouteObject, useSearchParams } from 'react-router-dom';
import { routes } from '../../routes';

export const Page: React.FC<PageProps> = ({ fixedHeader = false, children }) => {

  const [searchParams] = useSearchParams();

  const pageViewActions = async (path: string) => {
    window.scrollTo({ top: 0, behavior: 'smooth' });

    if (path && path.length) {

      let routeObjs: RouteObject[] = new Array<RouteObject>();

      routes.map(r => {
        routeObjs.push({
          element: r.component,
          path: r.to,
        });

        if (r.nestedRoutes) {
          r.nestedRoutes.map(nr => {
            routeObjs.push({
              element: nr.component,
              path: `${r.to}/${nr.to}`,
            });
          });
        }
      });

      const matches = matchRoutes(routeObjs, path);
      const isUserLoggedIn = apiService.instance.isLoggedIn();

      if (matches && matches.length && matches[0].route.path) {
        const destination = routes.find(r => r.to === matches[0].route.path || (r.nestedRoutes && r.nestedRoutes.find(nr => `${r.to}/${nr.to}` === matches[0].route.path)));

        if (destination && destination.requiresAuth && !isUserLoggedIn) {
          if (searchParams.get("code") === null || searchParams.get("code") === "") {
            localStorage.setItem("currentPage", window.location.href);
            doLogin(path);
          }
        } else {

          const currentPage = localStorage.getItem("currentPage");
          if (currentPage != null && (searchParams.get("code") === null || searchParams.get("code") === "")) {
            localStorage.removeItem("currentPage");
            window.location.href = currentPage;
          }
          else {
            await apiService.postInteraction(InteractionEventType.WebPageView, 'PageView', path);
          }
        }
      }
    }
  }

  usePageView(pageViewActions);

  return (
    (fixedHeader) ?
      <PageContainerFixedHeader>
        {children}
      </PageContainerFixedHeader> :
      <PageContainer>
        {children}
      </PageContainer>
  )
}